<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4 id="top">Coaches Index</h4>
      <router-link :to="{ name: 'UserNew'}" tag="button" class="btn btn-success" type="button">Create User</router-link>
    </div>
    <div v-if="users">

      <!-- Organization Index -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="rainbow-background"></div>
            <b-checkbox id="users-archived" v-model="filters.is_archived" v-on:input="getUsers(currentPage, perPage)" switch>
              View Archived Coaches
            </b-checkbox>
            <!-- Table Headers and Search Bar -->
            <ransack-filtering :headerWidth='20' :searchParam="searchParam" :tableHeaders="headers" @onChange="setFilters"></ransack-filtering>

            <div class="table-responsive">
              <table class="card-table table">
                <tbody class="text-left">
                <tr v-for="user in users" :key="user.id" :id="user.id">
                  <td class="w-20"> {{ user.first_name }} </td>
                  <td class="w-20"> {{ user.last_name }} </td>
                  <td class="w-20"> {{ user.email }} </td>
                  <td class="w-20"> {{ user.role }} </td>
                  <td class="w-20">
                    <div class="btn-group float-right">
                      <router-link v-if="isAdmin" :to="{ name: 'UserEdit', params: { id: user.id } }" tag="button" class="btn btn-secondary" type="button">Edit</router-link>
                      <router-link v-if="isAdmin || isUserOwner(user.id)" :to="{ name: 'UserShow', params: { id: user.id } }" tag="button" class="btn btn-primary" type="button">Show</router-link>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <!-- Pagination -->
            <pagination :totalRows="totalRows" :page="currentPage" :numOfRecords="perPage" @onChange="onChange"></pagination>

          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-else></loading-overlay>
  </div>
</template>

<script>

import { UserService } from '@/common/services/user.service';
import PermissionsService from '@/common/services/user_permissions.service';
import Pagination from '@/common/components/pagination.vue';
import RansackFiltering from '@/common/components/ransack-filtering.vue';
import { FilterService } from '@/common/services/filter.service';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'UserCoachesIndex',
  components: {
    Pagination,
    RansackFiltering,
    LoadingOverlay,
  },
  data() {
    return {
      users: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalRecords: 0,
      headers: ['First Name', 'Last Name', 'Email', 'Role', ''],
      searchParam: 'first_name_or_last_name_or_email_or_role_cont',
      filters: FilterService.defaultData(),
      isAdmin: false,
      isOwner: false,
    };
  },
  created() {
    this.getUsers(this.currentPage, this.perPage);
  },
  methods: {
    onChange(page, perPage) {
      this.getUsers(page, perPage);
    },
    getUsers(page, perPage) {
      this.filters.role_end = 'Coach';
      const params = FilterService.build({
        page,
        per_page: perPage,
      }, this.filters);
      UserService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.isAdmin = PermissionsService.isAdmin();
        this.users = response.data;
      });
    },
    isUserOwner(id) {
      return PermissionsService.isUserOwner(id);
    },
    setFilters(filters) {
      this.filters = filters;
      this.getUsers(this.currentPage, this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/users";
</style>
